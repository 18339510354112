import React from 'react'
import { PageProps, Link } from 'gatsby'
import styled from 'styled-components'

import media from 'styles/media'

import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

const StyledHeader = styled.div`
  width: 100%;
  height: 60vh;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h2 {
    margin-top: 100px;

    ${media.lg.min} {
      font-size: 36px;
    }
  }
`

const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.green};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  font-weight: 500;
  text-decoration: none;
  padding: 16px 20px;
  line-height: normal;
  border-radius: 34px;
  justify-content: center;
  align-items: center;
  border: ${({ theme }) => `2px solid ${theme.colors.green}`};
  transition: 0.3s;

  &:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.black};
  }

  ${media.lg.min} {
    max-width: 200px;
  }
`

const NotFoundPage: React.FC<PageProps> = () => {
  // useEffect(() => {
  //   navigate('/')
  // }, [])

  return (
    <Layout>
      <Seo title="Strona nie została odnaleziona" />

      <StyledHeader>
        <h2>Nie znaleziono strony</h2>
        <StyledButton as={Link} to="/">
          Strona główna
        </StyledButton>
      </StyledHeader>
    </Layout>
  )
}

export default NotFoundPage
